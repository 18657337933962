export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || 'Hgl3E4c6fHKlvU1aF5SPGkO1MOFL9zT6',
  domain: process.env.REACT_APP_AUTH0_DOMAIN || 'lively.au.auth0.com'
};

export const endpointConfig = {
  apiGateway: process.env.REACT_APP_APIGW_ENDPOINT || 'https://api.stg.munalively.com/graphql',
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};
